export const SmallElement = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
      <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
        <div style={{ fontSize: "48px", fontWeight: "700" }}>{title}</div>
        <div style={{ fontSize: "48px" }}>{text}</div>
      </div>
    </div>
  );
};
