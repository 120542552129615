import { sign } from "crypto";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import Menu from "../components/Menu";
import { saveDoodleChoice } from "../hooks/firebase";

const Doodle = ({ doodleData }: any) => {
  const [cookies, setCookie] = useCookies(["name"]);
  const [cookiesSel, setCookieSel] = useCookies(["selected"]);

  // console.log("Doodle data", doodleData);

  if (!doodleData) return <>"Loading..."</>;

  // console.log("cookiesSel", cookiesSel.selected);

  const error = doodleData.error;
  const loading = doodleData.loading;
  const data = doodleData.data;

  const options = data.get("options");
  console.log("options", options);

  // signups are stored in an object with the key being the name containing an array of dates
  const signups = data.get("signups");
  console.log("signups", signups);

  const votes = new Map();
  let sortedVotes = new Map();

  const neverDudes: Array<any> = [];

  let nonVotes = new Map();

  let voters: Array<any> = [];
  if (signups) voters = Object.keys(signups);

  /* summarize which date got how many users */
  if (!loading && data && signups) {
    Object.keys(signups).forEach((name: any) => {
      // console.log("\n\nName:", name);
      // console.log(name, signups[name]);
      if (signups[name]) {
        signups[name].forEach((date: any) => {
          // console.log("pre", votes.get(date));
          if (!votes.get(date)) {
            // init the array
            votes.set(date, [name]);
            // console.log("v after", votes.get(date));
          } else {
            // add the name to the existing array
            votes.set(date, [...votes.get(date), name]);
            // console.log("v after", votes.get(date));
          }
        });
      } else {
        // user didn't signup for any date
        // assign him to the neverDudes array
        console.log("never dude", name);
        neverDudes.push(name);
      }
    });

    // sort map by the date with the most votes
    sortedVotes = new Map([...votes].sort((a, b) => b[1].length - a[1].length));

    console.log("voters", voters);
    console.log("sortedVotes", sortedVotes);
    sortedVotes.forEach((votersArray, date) => {
      nonVotes.set(
        date,
        voters.filter((voter) => !votersArray.includes(voter))
      );
      console.log("date", date, "votersArray", votersArray);
    });
    console.log("nonVoters", nonVotes);
  }

  const validateName = (name: string) => {
    let error;
    if (!name) {
      error = "Ohne deinen Namen wird es schwierig 🙈";
    }
    return error;
  };

  return (
    <>
      <Menu />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ maxWidth: "600px" }}>
            {/* Auswahl */}
            {error && (
              <strong>
                <>Error: {error}</>
              </strong>
            )}
            {loading && <span>List: Loading...</span>}
            {!loading && !error && (
              <>
                <h3>
                  <span style={{ color: "red" }}>Turnier</span> Anmeldung
                </h3>
                <h5 style={{ marginTop: "20px" }}>
                  Bist du am Weihnachtspoker vom 27. Dezember 2023 18:00 mit dabei?
                </h5>
                <div>Falls ja bitte ausfüllen und abspeichern</div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: String(cookies.name || ""),
                    selected: cookiesSel?.selected,
                  }}
                  onSubmit={async (values, actions) => {
                    console.log("values.selected", values.selected);
                    if (values.name) {
                      saveDoodleChoice(values.name, values.selected);
                      setCookie("name", values.name);
                      setCookieSel("selected", values.selected);
                    }
                  }}
                >
                  {({ errors }) => (
                    <Form>
                      <div className="row">
                        <div className="col btns2 d-flex flex-row flex-wrap mb-3 mt-3 justify-content-start ">
                          {options.map((item: any, index: Number) => (
                            <div
                              key={item}
                              className=""
                              style={{ height: "50px" }}
                            >
                              <Field
                                type="checkbox"
                                name="selected"
                                value={item}
                                className="form-check-input"
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                                id={item}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={item}
                              >
                                {item}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="d-flex flex-row ">
                            <Field
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Dein Name hombre"
                              style={{
                                width: 200,
                                marginRight: "20px",
                                marginBottom: "15px",
                              }}
                              validate={validateName}
                            />
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{
                                height: "40px",
                              }}
                            >
                              Speichern
                            </button>
                          </div>
                          <div style={{ color: "red" }}>
                            {errors.name && <div>{errors.name}</div>}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            {/* Ergebnis */}
            <div>
              <h3 style={{ marginTop: "20px" }}>Teilnehmer</h3>
              Bis jetzt abgestimmt haben:
              <div>
                {voters.map((name, index) => (
                  <div>
                    <li>{name}</li>
                  </div>
                ))}
              </div>
              <h3 style={{ marginTop: "20px" }}>Ergebnis</h3>
              Folgendermassen haben die Teilnehmer abgestimmt:
              <div></div>
              <div>
                {[...sortedVotes.keys()].map((date, index) => (
                  <div className="mt-3" key={index}>
                    <div>
                      <div
                        className="mark "
                        style={{ padding: "5px", marginBottom: "5px" }}
                      >
                        {date}
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <div>Ja ({votes.get(date).length}x)</div>
                          <div>
                            {votes
                              .get(date)
                              .map((name: string, index: number) => (
                                <li key={index}>{name}</li>
                              ))}
                          </div>
                        </div>
                        {/* <div>
                          Nein ({nonVotes.get(date).length}x)
                          <div>
                            {nonVotes
                              .get(date)
                              .map((name: string, index: number) => (
                                <li key={index}>{name}</li>
                              ))}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* {neverDudes.length > 0 && (
                <div className="mt-3">
                  Folgende Dudes haben sich angemeldet, können aber nie (🙈):
                  {neverDudes.map((name: string, index: number) => (
                    <li key={index}>{name}</li>
                  ))}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Doodle;
