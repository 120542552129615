import React from "react";

export type ContainerProps = {
  children: React.ReactNode;
};

export const OuterContainer = (props: ContainerProps) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
    }}
  >
    {props.children}
  </div>
);

export const InnerContainer = (props: ContainerProps) => (
  <div
    style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: "20px",
    }}
  >
    {props.children}
  </div>
);
