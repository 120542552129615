import React from "react";
import { useList } from "react-firebase-hooks/database";
import { ref } from "firebase/database";
import { addRSVP, database, deletePlayer, deleteRSVP } from "../hooks/firebase";
import { Formik, Field, Form } from "formik";
import { useCookies } from "react-cookie";
import { Button } from "react-bootstrap";
import Menu from "../components/Menu";
import { OuterContainer, InnerContainer } from "../components/OuterContainer";

const Signup: React.FC = () => {
  const [data, loading, error] = useList(ref(database, "rsvp"));
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  let yes: string[] = [];
  let no: string[] = [];
  let maybe: string[] = [];

  const voting_options = ["Ja", "Nein", "Vielleicht"];

  if (!loading && data) {
    data.map((v) => {
      if (v.key) {
        if (v.val().participation === "Ja") {
          // firebase returns the same element multiple times in data -> filter it out using a map
          if (!yes.includes(v.key)) {
            yes.push(v.key);
          }
        } else if (v.val().participation === "Nein") {
          if (!no.includes(v.key)) {
            no.push(v.key);
          }
        } else if (v.val().participation === "Vielleicht") {
          if (!maybe.includes(v.key)) {
            maybe.push(v.key);
          }
        }
      }
      return true;
    });
  }

  return (
    <>
      <Menu />
      <OuterContainer>
        <InnerContainer>
          <div>
            <h3>
              <span style={{ color: "blue" }}>Mittwoch</span> Poker
            </h3>
            <h5 style={{ marginTop: "20px" }}>
              Bist du diesen Mittwoch dabei?
            </h5>
            {error && (
              <strong>
                <>Error: {error}</>
              </strong>
            )}
            {loading && <span>List: Loading...</span>}
            <Formik
              initialValues={{
                selected: "Ja",
                name: "",
              }}
              onSubmit={async (values, actions) => {
                if (values.name) {
                  setCookie("name", values.name);
                  addRSVP(values.name, values.selected);
                  actions.resetForm();
                }
              }}
            >
              {({ values }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 btns" style={{ marginTop: "10px" }}>
                      {voting_options.map((item, index) => (
                        <div key={item}>
                          <Field
                            type="radio"
                            name="selected"
                            value={item}
                            className="form-check-input"
                            id={item}
                          />
                          <label className="form-check-label" htmlFor={item}>
                            {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className="d-inline-flex justify-content-start flex-wrap"
                    style={{ marginTop: "15px" }}
                  >
                    <div>
                      <Field
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Dein Name hombre"
                        style={{
                          width: 200,
                          marginRight: "20px",
                          marginBottom: "15px",
                        }}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        height: "40px",
                      }}
                    >
                      Speichern
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {!loading && !error && (
              <>
                <div style={{ marginTop: "20px" }}>
                  <h5> Ja (👍)</h5>
                  <ul>
                    {yes.map((name, id) => (
                      <li key={id}>
                        <span>{name} </span>
                        {name === cookies["name"] && (
                          <span>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={(e) => {
                                removeCookie("name");
                                deleteRSVP(name);
                                deletePlayer(name);
                              }}
                            >
                              löschen
                            </Button>
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>

                  <h5>Nein (💩)</h5>
                  <ul>
                    {no.map((name, id) => (
                      <li key={id}>{name}</li>
                    ))}
                  </ul>
                  <h5>Vielleicht (?)</h5>
                  <ul>
                    {maybe.map((name, id) => (
                      <li key={id}>{name}</li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </InnerContainer>
      </OuterContainer>
    </>
  );
};

export default Signup;
