import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Setup from "./pages/Setup";
import Blinds from "./pages/Blinds";
import Signup from "./pages/Signup";
import Payout from "./pages/Payout";
import { CookiesProvider } from "react-cookie";
import { useTimer } from "react-timer-hook";
import useSound from "use-sound";
import fanfare from "./level_change.mp3";

import {
  usePersistedList,
  DB_GAME_CONTROL_PATH,
  DB_GAME_CONTROL_STATUS_KEY,
  DB_GAME_CONTROL_STATUS_RUNNING,
  DB_GAME_CONTROL_BLIND_DURATION_KEY,
  DB_GAME_CONTROL_STATUS_TIME_MINUTES,
  DB_GAME_CONTROL_STATUS_TIME_SECONDS,
  DB_GAME_CONTROL_BLIND_LEVEL_KEY,
  DB_GAME_CONTROL_BLINDS_KEY,
  DB_PLAYER_PATH,
  DB_GAME_CONTROL_DEFAULT_BUYIN_KEY,
  DB_GAME_CONTROL_DEFAULT_CHIPS_KEY,
  DB_GAME_CONTROL_PAYOUTS_KEY,
  DB_PATH_DOODLE,
  DB_GAME_CONTROL_STATUS_TIME_START,
  pauseGame,
} from "./hooks/firebase";
// import useSound from "use-sound";
// import fanfare from "./level_change.mp3";
import Doodle from "./pages/Doodle";

export type DataTypes = {
  isGameRunning: boolean;
  blindDuration: number;
  minutes: number;
  seconds: number;
  level: number;
  blinds: Array<string>;
  error: any;
  loading: boolean;
  players: Map<string, any>;
  buyin: number;
  chips: number;
  payouts: Array<string>;
};

export type DataPropsType = {
  data: DataTypes;
};

export type DoodleDataPropsType = {
  data: Map<string, any>;
};

const App = () => {
  const [map, loading, error] = usePersistedList(DB_GAME_CONTROL_PATH);
  const [players] = usePersistedList(DB_PLAYER_PATH);
  const [playFanfare, { sound }] = useSound(fanfare);
  const [doodleData, doodleError, doodleLoading] =
    usePersistedList(DB_PATH_DOODLE);
  const doodle = {
    data: doodleData,
    error: doodleError,
    loading: doodleLoading,
  };

  const [timerStatus, setTimerStatus] = useState(false);

  const lastMinutes = Number(map.get(DB_GAME_CONTROL_STATUS_TIME_MINUTES));
  const lastSeconds = Number(map.get(DB_GAME_CONTROL_STATUS_TIME_SECONDS));
  const lastLevel = Number(map.get(DB_GAME_CONTROL_BLIND_LEVEL_KEY));
  const lastStartDate = new Date(map.get(DB_GAME_CONTROL_STATUS_TIME_START));

  // prep data for child components
  const data: DataTypes = {
    isGameRunning:
      map.get(DB_GAME_CONTROL_STATUS_KEY) === DB_GAME_CONTROL_STATUS_RUNNING,
    minutes: lastMinutes,
    seconds: lastSeconds,
    level: lastLevel,
    blindDuration: Number(map.get(DB_GAME_CONTROL_BLIND_DURATION_KEY)),
    blinds: map.get(DB_GAME_CONTROL_BLINDS_KEY)
      ? map.get(DB_GAME_CONTROL_BLINDS_KEY).split(",")
      : [],
    error: error,
    loading: loading,
    players: players,
    buyin: Number(map.get(DB_GAME_CONTROL_DEFAULT_BUYIN_KEY)),
    chips: Number(map.get(DB_GAME_CONTROL_DEFAULT_CHIPS_KEY)),
    payouts: map.get(DB_GAME_CONTROL_PAYOUTS_KEY)
      ? map.get(DB_GAME_CONTROL_PAYOUTS_KEY).split(",")
      : [],
  };

  const [level, setLevel] = useState(data.level);

  // init timer, value will be adjusted later
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // will be set later
  const { seconds, minutes, isRunning, pause, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: time,
  });

  // manage level changes
  useEffect(() => {
    if (timerStatus && minutes === 0 && seconds === 0) {
      // console.log("level change");
      const playSound = () => {
        const msg = new SpeechSynthesisUtterance();
        if (level < data.blinds.length) {
          const tmpblinds = data.blinds[level].replace("/", ", ");
          const blinds = tmpblinds.replaceAll("'", "");
          msg.text = "Jungs, neuer Blaind Level, " + blinds;
          msg.pitch = 0.5;
          msg.rate = 1.7;
          // console.log("adding speech");

          sound.once("end", () => {
            window.speechSynthesis.cancel();
            window.speechSynthesis.speak(msg);
          });
        }
        // console.log("playing fanfare");
        playFanfare();
      };
      if (data.level < data.blinds.length) {
        if (window.location.pathname === "/tourneytimer") {
          playSound();
        }

        setLevel(level + 1);
        const time = new Date();
        time.setSeconds(time.getSeconds() + data.blindDuration * 60);
        restart(time);
      } else {
        const dataCopy = { ...data };
        dataCopy.minutes = 0;
        dataCopy.seconds = 0;
        pauseGame(dataCopy);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds, level, data, timerStatus]);

  // console.log("Init: data", data);
  // console.log(
  //   "Init: timer - status:" + isRunning + " " + minutes + ":" + seconds
  // );
  // console.log("timerStatus:" + timerStatus);

  if (data.isGameRunning) {
    if (!timerStatus) {
      // start timer, figure out how much time is left
      // console.log("Initializing timer");
      // console.log(
      //   "Saved values: L" + lastLevel + " " + lastMinutes + ":" + lastSeconds
      // );
      const levelLengthInSeconds = data.blindDuration * 60;
      const offset = (Date.now() - Number(lastStartDate)) / 1000;
      const totalSecondsPassed =
        (lastLevel - 1) * levelLengthInSeconds +
        levelLengthInSeconds -
        (lastMinutes * 60 + lastSeconds) +
        offset;
      // console.log("offset: " + offset);
      // console.log("totalSecondsPassed: " + totalSecondsPassed);

      setLevel(Math.ceil(totalSecondsPassed / data.blindDuration / 60));
      // console.log("Setting local level to", data.level);

      const secondsLeft =
        levelLengthInSeconds - (totalSecondsPassed % levelLengthInSeconds);
      const time = new Date();
      time.setSeconds(time.getSeconds() + secondsLeft); // 10 minutes timer
      restart(time);

      // console.log("Timer has been armed to: " + secondsLeft);
      setTimerStatus(true);
    } else {
      // game and timer are running
      // console.log(
      //   "timer - status:" + isRunning + " " + minutes + ":" + seconds
      // );
      if (isRunning) {
        data.minutes = minutes;
        data.seconds = seconds;
        data.level = level;
        // console.log(
        //   "local values updated to: " +
        //     "L" +
        //     level +
        //     " " +
        //     zeroPad(data.minutes, 2) +
        //     ":" +
        //     zeroPad(data.seconds, 2)
        // );
      }
    }
  } else {
    // console.log("game not running");
    // game not running
    if (timerStatus) {
      console.log("stopping timer");
      pause();
      setTimerStatus(false);
    }
  }

  // console.log(
  //   "data written: L" +
  //     data.level +
  //     " " +
  //     zeroPad(data.minutes, 2) +
  //     ":" +
  //     zeroPad(data.seconds, 2)
  // );

  return (
    <CookiesProvider>
      <BrowserRouter>
        <div className="inter-font">
          {error && (
            <>
              <h5> Errors:</h5>
              <strong>
                <>Error: {error}</>
              </strong>
            </>
          )}
          {loading && <span>Loading...</span>}
          {!loading && !error && (
            <div>
              <Routes>
                <Route path="/" element={<Signup />}></Route>
                <Route
                  path="/tourneysetup"
                  element={<Setup data={data} />}
                ></Route>
                <Route
                  path="/tourneytimer"
                  element={<Blinds data={data} />}
                ></Route>
                <Route
                  path="/doodle"
                  element={<Doodle doodleData={doodle} />}
                ></Route>
                <Route path="/payout" element={<Payout data={data} />}></Route>
              </Routes>
            </div>
          )}
        </div>
      </BrowserRouter>
    </CookiesProvider>
  );
};

export default App;
