import React from "react";
import { Timer2 } from "../components/Timer2";
import { DataPropsType } from "../App";

const Blinds = ({ data }: DataPropsType) => {
  return (
    <div style={{ backgroundColor: "darkgreen", color: "white" }}>
      <Timer2 data={data} />
    </div>
  );
};

export default Blinds;
