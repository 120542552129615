import { DataPropsType } from "../App";

export const zeroPad = (num: number, places: number) => {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

const TimerDisplay = ({ data }: DataPropsType) => {
  const { isGameRunning, level, blinds, minutes, seconds } = data;
  return (
    // <Row md="12">
    <div style={{ textAlign: "center", marginBottom: "40px" }}>
      <div
        style={{
          fontSize: "120px",
          fontWeight: "600",
          lineHeight: "150px",
          paddingTop: "5%",
        }}
      >
        <div>
          <span>{zeroPad(minutes, 2)}</span>:
          <span>{zeroPad(Math.round(seconds), 2)}</span>
        </div>
        <div
          style={{
            fontSize: "80px",
            fontWeight: "600",
            color: "yellow",
          }}
        >
          Blinds: {blinds[level - 1]}
        </div>
        <div
          style={{
            fontSize: "30px",
            fontWeight: "300",
            lineHeight: "100px",
          }}
        >
          Nächster Level: {blinds[level]}
        </div>
      </div>

      {!isGameRunning && (
        <div style={{ fontSize: "100px", color: "red" }}>
          <span>Pause</span>
        </div>
      )}
      {isGameRunning && (
        <div style={{ fontSize: "100px", color: "darkgreen" }}>
          <span>spacer</span>
        </div>
      )}
    </div>
    // </Row>
  );
};

export default TimerDisplay;
