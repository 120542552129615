import React from "react";
import { DataPropsType, DataTypes } from "../App";
import Menu from "../components/Menu";
import {
  DB_PATH_WINNERS,
  saveWinners,
  usePersistedList,
} from "../hooks/firebase";

const placeHolder = "---xxx---";

const Payout = ({ data }: DataPropsType) => {
  // console.log("data:", data);
  const nbPayouts = data.payouts.length;
  const players = [...data.players].map(([key, value]) => key);
  // so that we can loop n times, where n is the number of payouts
  const placesIterator = [...Array(nbPayouts)];
  // const [winners, setWinners] = React.useState([...Array(nbPayouts)]);
  // console.log("winners", winners);
  // calculate payouts

  let totalBuyin = 0;
  data.players.forEach((value) => {
    totalBuyin += value.buyin;
  });

  const [dbData, loading, error] = usePersistedList(DB_PATH_WINNERS);
  const winners: Array<string> = [];
  for (let i = 0; i < nbPayouts; i++) {
    winners.push(placeHolder);
  }

  if (!loading && !error && dbData) {
    // console.log(dbData);
    dbData.forEach((value: string, key: string) => {
      winners[Number(key)] = value;
    });
    // console.log("winners", winners);
  }

  const allWinnersKnown = winners.every((winner) => winner !== placeHolder);

  let winnerMatrix: { [key: string]: any } = [];
  let transferArray: Array<string> = [];
  if (allWinnersKnown) {
    // calculate winner matrix
    winnerMatrix = calculateWinnerMatrix(
      players,
      data,
      winners,
      totalBuyin,
      winnerMatrix
    );
    // console.log("matrix", winnerMatrix);
    // calculate transfer array
    calculateTransfers(winnerMatrix, transferArray);
    // console.log("t", transferArray);
  }

  return (
    <>
      <Menu />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <div style={{ maxWidth: "600px" }}>
            <h3>Auszahlung</h3>
            <p>
              Na, was gewonnen du Sack? Hier siehst du wer was gewonnen hat und
              wer wem abdrücken darf.
            </p>

            {/* Eingabe */}
            <div className="mt-4">
              <h5>Eingabe</h5>
            </div>
            <div className="d-flex flex-row justify-content-start">
              <div>
                {placesIterator.map((value, index) => {
                  return (
                    <div key={index} className="d-flex align-items-center mb-3">
                      <div style={{ width: "70px" }}> {index + 1}. Platz:</div>
                      <div>
                        <select
                          className="form-select"
                          value={winners[index]}
                          aria-label="size 3 select example"
                          onChange={(event) => {
                            const newWinners = [...winners];
                            newWinners[index] = event.target.value;
                            saveWinners(newWinners);
                            // console.log("saved", newWinners);
                          }}
                          // defaultValue="Bitte wählen"
                        >
                          <option value={placeHolder} disabled hidden key="xxx">
                            Bitte wählen
                          </option>
                          {players.map((player) => {
                            return (
                              <option value={player} key={player}>
                                {player}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mx-5">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    saveWinners([]);
                  }}
                >
                  reset
                </button>
              </div>
            </div>

            {/* ergebnis */}
            <div className="mt-4">
              <h5>Ergebnis</h5>
            </div>
            <div>
              {!allWinnersKnown && (
                <div>Bitte alle {nbPayouts} Gewinner auswählen.</div>
              )}
            </div>
            <div>
              {hasDuplicates(winners) && (
                <div>Bitte niemanden doppelt aufführen.</div>
              )}
            </div>
            <div>
              {allWinnersKnown && !hasDuplicates(winners) && (
                <div>
                  <div className="table-responsive">
                    <table className="table table-responsive table-bordered table-hover">
                      <thead className="table-light">
                        <tr>
                          <td>Name</td>
                          <td>Gewinn</td>
                          <td>Buyin</td>
                          <td>Netto 💰💲💵</td>
                        </tr>
                      </thead>
                      <tbody>
                        {winnerMatrix.map((data: any, index: Number) => {
                          return (
                            <tr key={String(index)}>
                              <td>{data.name}</td>
                              <td>CHF {data.won}</td>
                              <td>CHF {data.buyin}</td>
                              <td style={{ color: "green", fontWeight: "600" }}>
                                CHF {data.netto}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <p>Gratulation an die Gewinner!</p>
                  </div>
                  <div>
                    <div className="mt-4">
                      <h5>Transferliste</h5>
                      <div>
                        {transferArray.map((entry, index) => {
                          return <li key={index}>{entry}</li>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function hasDuplicates(arr: string[]) {
  var counts: { [key: string]: any } = [];

  for (var i: number = 0; i <= arr.length; i++) {
    if (counts[arr[i]] === undefined) {
      counts[arr[i]] = 1;
    } else {
      return true;
    }
  }
  return false;
}

function calculateTransfers(winnerMatrix: any, transferArray: any) {
  winnerMatrix.forEach(
    (entry: { netto: number; name: string }, index: number) => {
      if (index > 0) {
        if (entry.netto > 0) {
          transferArray.push(
            winnerMatrix[0].name +
              " schickt " +
              entry.name +
              " CHF " +
              entry.netto
          );
        } else {
          transferArray.push(
            winnerMatrix[0].name +
              " fordert von " +
              entry.name +
              " CHF " +
              Math.abs(entry.netto)
          );
        }
      }
    }
  );
}

function calculateWinnerMatrix(
  players: string[],
  data: DataTypes,
  winners: any[],
  totalBuyin: number,
  winnerMatrix: { [key: string]: any }
) {
  players.forEach((player) => {
    const buyin = data.players.get(player)?.buyin;
    let won = 0;
    const winnerIndex = winners.indexOf(player);
    // console.log("player", player, "winnerIndex", winnerIndex);
    if (winnerIndex !== -1) {
      won = (Number(data.payouts[winnerIndex]) * totalBuyin) / 100;
    }
    winnerMatrix.push({
      name:
        winnerIndex !== -1 ? player + " (" + (winnerIndex + 1) + ")" : player,
      buyin: buyin,
      won: won,
      netto: won - buyin,
    });

    winnerMatrix = winnerMatrix.sort((a: any, b: any) => b.netto - a.netto);
  });
  return winnerMatrix;
}

export default Payout;
