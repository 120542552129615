import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const Menu: React.FC = () => {
  return (
    <div>
      <Container style={{ padding: "0px" }}>
        {/* style={{
          display: "flex",
          justifyContent: "center",
        }}
      > */}
        <Navbar expand="lg">
          <Container style={{ padding: "0px" }}>
            <Navbar.Brand href="/">Rümli Automatron 3000</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="justify-content-center">
                <Nav.Link href="/">Mittwoch</Nav.Link>
                <Nav.Link href="/doodle">Turnier/doodle</Nav.Link>
                <Nav.Link href="/tourneysetup">Einstellungen</Nav.Link>
                <Nav.Link href="/tourneytimer">Blinds</Nav.Link>
                <Nav.Link href="/payout">Payout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </div>
  );
};

export default Menu;
