import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Trash, PlusLg } from "react-bootstrap-icons";
import { AiOutlineMinus } from "react-icons/ai";
import { FaSkull } from "react-icons/fa";
import { DataTypes } from "../App";
import Menu from "../components/Menu";
import { zeroPad } from "../components/TimerDisplay";
import {
  addPlayer,
  deletePlayer,
  pauseGame,
  saveBuyin,
  startGame,
  saveLevel,
  saveTime,
  saveChipsAmount,
  saveBlindDuration,
  saveBlindStructure,
  savePayoutStructure,
  saveBuyinAmount,
  eliminatePlayer,
} from "../hooks/firebase";

export type DataPropsType = {
  data: DataTypes;
};

const Setup = ({ data }: DataPropsType) => {
  const [newPlayerName, setNewPlayerName] = useState("");
  const [newBlindLevel, setNewBlindLevel] = useState(String(data.level));

  const [newMinutes, setNewMinutes] = useState(String(data.minutes));
  const [newSeconds, setNewSeconds] = useState(String(data.seconds));
  const [newChipsAmount, setNewChipsAmount] = useState(String(data.chips));
  const [newBuyin, setNewBuyin] = useState(String(data.buyin));
  const [newBlindDuration, setNewBlindDuration] = useState(
    String(data.blindDuration)
  );
  const [newBlindStructure, setNewBlindStructure] = useState(
    data.blinds.join()
  );
  const [newPayoutStructure, setNewPayoutStructure] = useState(
    data.payouts.join()
  );

  const players = [...data.players].map(([key, value]) => key);
  // console.log("🚀 ~ Setup ~ data.players:", data.players);
  // console.log("🚀 ~ Setup ~ players:", players);

  return (
    <>
      <Menu />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100vw",
          // border: "1px solid red",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            // border: "1px solid black",
          }}
        >
          <Container
            style={{
              maxWidth: "410px",
              display: "inline-block",
            }}
          >
            {/* Titel */}
            <Row>
              <Col md="12">
                <h3>Turnier Einstellungen</h3>
              </Col>
            </Row>

            {/* Steuerung */}
            <Row>
              <Col>
                <h5>Aktueller Status</h5>
                <div>
                  <li>
                    Spiel:{" "}
                    <span>{data.isGameRunning ? "läuft" : "pausiert"}</span>
                  </li>
                  <li>Level: {data.level}</li>
                  <li>
                    Timer: {zeroPad(data.minutes, 2)}:{zeroPad(data.seconds, 2)}
                  </li>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h5 style={{ marginTop: "20px" }}>Spielsteuerung</h5>
                <div
                  style={{
                    marginTop: "10px",
                    paddingBottom: "5px",
                  }}
                >
                  Spiel starten/stoppen:
                  <span style={{ float: "right" }}>
                    {data.isGameRunning && (
                      <Button
                        size="sm"
                        variant="danger"
                        style={{ width: "85px" }}
                        onClick={(e) => {
                          pauseGame(data);
                        }}
                      >
                        Pause
                      </Button>
                    )}
                    {!data.isGameRunning && (
                      <Button
                        size="sm"
                        style={{ width: "85px" }}
                        onClick={(e) => {
                          startGame();
                        }}
                      >
                        Start
                      </Button>
                    )}
                  </span>
                </div>
              </Col>
            </Row>

            {/* Blind Level */}
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Level:</div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputGroup size="sm">
                        <FormControl
                          value={newBlindLevel}
                          placeholder="Neuer Level"
                          style={{ width: "40px" }}
                          onChange={(e) => {
                            setNewBlindLevel(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div>
                    <Button
                      variant="primary"
                      id="button-addon2"
                      size="sm"
                      style={{ width: "85px" }}
                      onClick={(e) => {
                        if (!!newBlindLevel) {
                          setNewBlindLevel(newBlindLevel);
                          saveLevel(newBlindLevel);
                        }
                      }}
                    >
                      Speichern
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Timer Level */}
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "7px",
                      }}
                    >
                      Timer:
                    </div>
                    <div>
                      <InputGroup size="sm">
                        <FormControl
                          style={{ width: "50px" }}
                          value={Number(newMinutes)}
                          placeholder="Minuten"
                          onChange={(e) => {
                            setNewMinutes(e.target.value);
                          }}
                        />
                        <FormControl
                          style={{ width: "50px" }}
                          value={zeroPad(Number(newSeconds), 2)}
                          placeholder="Sekunden"
                          onChange={(e) => {
                            setNewSeconds(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <Button
                    variant="primary"
                    disabled={data.isGameRunning}
                    id="button-addon3"
                    size="sm"
                    style={{ width: "85px" }}
                    onClick={(e) => {
                      if (!!newMinutes && !!newSeconds) {
                        saveTime(parseInt(newMinutes), parseInt(newSeconds));
                      }
                    }}
                  >
                    Speichern
                  </Button>
                </div>
              </Col>
            </Row>

            {/* Dauer */}
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Dauer:</div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputGroup size="sm">
                        <FormControl
                          value={newBlindDuration}
                          placeholder="Dauer"
                          style={{ width: "80px" }}
                          onChange={(e) => {
                            setNewBlindDuration(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div>
                    <Button
                      variant="primary"
                      id="button-addon2"
                      size="sm"
                      style={{ width: "85px" }}
                      onClick={(e) => {
                        if (!!newBlindDuration) {
                          setNewBlindDuration(newBlindDuration);
                          saveBlindDuration(newBlindDuration);
                        }
                      }}
                    >
                      Speichern
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Chips */}
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Chips:</div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputGroup size="sm">
                        <FormControl
                          value={newChipsAmount}
                          placeholder="Neuer Level"
                          style={{ width: "80px" }}
                          onChange={(e) => {
                            setNewChipsAmount(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div>
                    <Button
                      variant="primary"
                      id="button-addon2"
                      size="sm"
                      style={{ width: "85px" }}
                      onClick={(e) => {
                        if (!!newChipsAmount) {
                          setNewChipsAmount(newChipsAmount);
                          saveChipsAmount(newChipsAmount);
                        }
                      }}
                    >
                      Speichern
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Buy-in */}
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Buy-in:</div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <InputGroup size="sm">
                        <FormControl
                          value={newBuyin}
                          placeholder="Neuer Buyin"
                          style={{ width: "80px" }}
                          onChange={(e) => {
                            setNewBuyin(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div>
                    <Button
                      variant="primary"
                      id="button-addon2"
                      size="sm"
                      style={{ width: "85px" }}
                      onClick={(e) => {
                        if (!!newBuyin) {
                          setNewBuyin(newBuyin);
                          saveBuyinAmount(newBuyin);
                        }
                      }}
                    >
                      Speichern
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Spieler Anzeige*/}
            <Row style={{ marginTop: "30px" }}>
              <Col md="12">
                <h5>Spieler</h5>
                {players.map((name: string, id: number) => (
                  <div
                    style={{
                      paddingBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={id}
                  >
                    <div
                      style={{
                        width: "150px",
                        wordWrap: "break-word",
                        display: "inline-block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        paddingRight: "5px",
                        marginRight: "5px",
                        textDecoration: data.players.get(name).alive
                          ? "none"
                          : "line-through",
                      }}
                    >
                      {name}
                      {data.players.get(name).alive ? "" : " (out)"}
                    </div>
                    <span
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      CHF {data.players.get(name).buyin}
                    </span>
                    <div style={{ float: "right" }}>
                      <button
                        className="btn-primary"
                        onClick={(e) => {
                          saveBuyin(
                            name,
                            data.players.get(name).buyin + data.buyin,
                            true
                          );
                        }}
                      >
                        <PlusLg />
                      </button>
                      <button
                        className="btn-primary"
                        style={{ marginLeft: "5px" }}
                        onClick={(e) => {
                          if (data.players.get(name).buyin > 0) {
                            saveBuyin(
                              name,
                              data.players.get(name).buyin - data.buyin,
                              true
                            );
                          }
                        }}
                      >
                        <AiOutlineMinus />
                      </button>
                      <button
                        style={{ marginLeft: "5px" }}
                        className="btn-primary"
                        onClick={(e) => {
                          deletePlayer(name);
                        }}
                      >
                        <Trash />
                      </button>
                      <button
                        style={{ marginLeft: "5px" }}
                        className="btn-primary"
                        onClick={(e) => {
                          eliminatePlayer(name);
                        }}
                      >
                        <FaSkull />
                      </button>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>

            {/* Spieler hinzufügen */}
            <Row>
              <Col>
                <InputGroup className="mb-3">
                  <FormControl
                    value={newPlayerName}
                    placeholder="Spieler hinzufügen"
                    onChange={(e) => {
                      if (!!e.target.value) setNewPlayerName(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        console.log("Enter key pressed", newPlayerName);
                        if (newPlayerName && !players.includes(newPlayerName)) {
                          addPlayer(newPlayerName);
                          setNewPlayerName("");
                        }
                      }
                    }}
                  />
                  <button
                    style={{ width: "78px" }}
                    className="btn btn-primary"
                    onClick={(e) => {
                      console.log("Enter key pressed", newPlayerName);
                      if (newPlayerName && !players.includes(newPlayerName)) {
                        addPlayer(newPlayerName);
                        setNewPlayerName("");
                      }
                    }}
                  >
                    <PlusLg />
                  </button>
                </InputGroup>
              </Col>
            </Row>

            {/* Struktur */}
            <Row>
              <Col>
                <h5 style={{ marginTop: "20px" }}>Struktur</h5>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <FormControl
                    as="textarea"
                    rows={25}
                    value={newBlindStructure}
                    onChange={(e) => {
                      setNewBlindStructure(e.target.value);
                    }}
                  />
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      variant="primary"
                      id="button-addon2"
                      size="sm"
                      onClick={(e) => {
                        if (!!newBlindStructure) {
                          saveBlindStructure(newBlindStructure);
                        }
                      }}
                    >
                      Speichern
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {/* Payouts */}
            <Row>
              <Col>
                <h5 style={{ marginTop: "20px" }}>Payouts</h5>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <FormControl
                    as="textarea"
                    rows={3}
                    value={newPayoutStructure}
                    onChange={(e) => {
                      setNewPayoutStructure(e.target.value);
                    }}
                  />
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      variant="primary"
                      id="button-addon2"
                      size="sm"
                      onClick={(e) => {
                        if (!!newPayoutStructure) {
                          savePayoutStructure(newPayoutStructure);
                        }
                      }}
                    >
                      Speichern
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Setup;
