import { DataPropsType } from "../App";
import QRCode from "react-qr-code";
import { SmallElement } from "./SmallElement";
import { zeroPad } from "./zeroPad";

export const Timer2 = ({ data }: DataPropsType) => {
  const { chips, buyin, players, minutes, seconds, payouts, blinds, level } =
    data;

  console.log("🚀 ~ Timer2 ~ data:", data);

  const totalBuyins = Array.from(players.values()).reduce(
    (sum, player) => sum + player.buyin,
    0
  );

  const totalChips = (totalBuyins * chips) / buyin;

  console.log("🚀 ~ Timer2 ~ totalBuyins:", totalBuyins);

  const currentLevel = blinds[level - 1];
  const nextLevel =
    level < blinds.length - 1 ? blinds[level] : "game over, baby!";

  let formattedPayouts = (
    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
      {payouts.map((payout, index) => {
        return (
          <div className="px-3" id={index.toString()}>
            {index + 1}. Platz: CHF{" "}
            {Math.round((Number(payout) * totalBuyins) / 100)}
            {index < payouts.length - 1 ? ", " : ""}
          </div>
        );
      })}
    </div>
  );

  const eliminatedPlayers = Array.from(data.players.values()).filter(
    (player) => player.alive === false
  ).length;
  console.log("🚀 ~ Timer2 ~ eliminatedPlayers:", eliminatedPlayers);

  const playersStillInGame = data.players.size - eliminatedPlayers;
  console.log("🚀 ~ Timer2 ~ playersStillInGame:", playersStillInGame);

  const averageStack = Math.round(
    ((totalBuyins / data.buyin) * data.chips) / playersStillInGame
  );
  console.log("🚀 ~ Timer2 ~ averageStack:", averageStack);

  return (
    <>
      {/* desktop iew */}
      <div className="d-none d-xl-block">
        <div
          className="d-flex flex-column justify-content-center align-items-center flex-fill"
          style={{ width: "100vw", height: "100vh" }}
        >
          <div className="d-flex flex-row h-75 justify-content-center align-items-center w-100">
            <div className="d-flex flex-column w-25 h-100 align-items-center justify-content-around border-bottom flex-fill">
              <div className="d-flex flex-fill ">
                <SmallElement
                  title="Buy-In"
                  text={`CHF ${buyin.toLocaleString("de-CH")}`}
                />
              </div>
              <div className="d-flex flex-fill w-100 border-top border-bottom">
                <SmallElement
                  title="Starting Chips"
                  text={`${chips.toLocaleString("de-CH")}`}
                />
              </div>

              <div className="d-flex flex-fill">
                <SmallElement
                  title="Total Chips"
                  text={`${totalChips.toLocaleString("de-CH")}`}
                />
              </div>
            </div>
            <div className="d-flex flex-column w-50 h-100 align-items-center border-bottom border-start border-end ">
              <div
                style={{
                  fontSize: "180px",
                  fontWeight: "700",
                  marginTop: "120px",
                  color: "yellow",
                }}
              >
                {`${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`}
              </div>
              <div
                style={{
                  fontSize: "140px",
                  fontWeight: "600",
                  color: "yellow",
                }}
              >
                {currentLevel}
              </div>
              <div style={{ fontSize: "40px", marginTop: "20px" }}>
                Nächstes Level: {nextLevel}
              </div>
            </div>
            <div className="d-flex flex-column w-25 h-100 align-items-center justify-content-around border-bottom">
              <SmallElement
                title="Spieler"
                text={` ${playersStillInGame.toLocaleString(
                  "de-CH"
                )} / ${players.size.toLocaleString("de-CH")}`}
              />
              <div className="d-flex flex-fill w-100 border-top border-bottom">
                <SmallElement
                  title="Total Buy-Ins"
                  text={`CHF ${totalBuyins.toLocaleString("de-CH")}`}
                />
              </div>

              <SmallElement
                title="Average Stack"
                text={averageStack.toLocaleString("de-CH")}
              />
            </div>
          </div>
          <div className="d-flex flex-row h-25 w-100 justify-content-center align-items-center">
            <div className="d-flex flex-column w-25 h-100 align-items-center justify-content-around">
              <div></div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center flex-fill w-50 h-100 border-start border-end">
              <div>
                <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
                  <div className="d-flex flex-column justify-content-center align-items-center flex-fill">
                    <div style={{ fontSize: "48px", fontWeight: "700" }}>
                      Preisgeld
                    </div>
                    <div
                      className="text-center"
                      style={{ fontSize: "32px", padding: "" }}
                    >
                      {formattedPayouts}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center flex-fill w-25">
              <div>
                <QRCode
                  size={180}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={"https://www.kalenderfee.com/tourneytimer"}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-xl-none">
        <div
          className="d-flex flex-column"
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "white",
            color: "black",
            padding: "20px",
          }}
        >
          <div>
            <h5>Mobile Ansicht</h5>
            Für die grüne Standardansicht kannst du einfach den Screen breiter
            machen.
          </div>
          <div className="pt-2">
            Timer: {minutes}:{seconds}
          </div>
          <div>Level: {currentLevel}</div>
          <div>Nächstes Level: {nextLevel}</div>
          <div>Chips: {chips}</div>
          <div>Buyin: CHF {buyin}</div>
          <div>Total Buy-Ins: {totalBuyins.toLocaleString("de-CH")}</div>
          <div>Total Chips: {(chips * buyin).toLocaleString("de-CH")}</div>
          <div>
            Spieler: {playersStillInGame.toLocaleString("de-CH")} /{" "}
            {players.size}
          </div>
          <div>Total Buy-Ins: {totalBuyins.toLocaleString("de-CH")}</div>
          <div>Average Stack: {averageStack.toLocaleString("de-CH")}</div>
          <div>
            Preisgeld:{" "}
            {payouts.map((payout, index) => {
              return (
                <div className="px-3" id={index.toString()}>
                  {index + 1}. Platz: CHF{" "}
                  {Math.round((Number(payout) * totalBuyins) / 100)}
                  {index < payouts.length - 1}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
