import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, DataSnapshot, get } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import { DataTypes } from "../App";

export const DB_PLAYER_PATH = "/players/";
export const DB_GAME_CONTROL_PATH = "/game_status/";
export const DB_GAME_CONTROL_STATUS_KEY = "status";
export const DB_GAME_CONTROL_STATUS_RUNNING = "Läuft";
export const DB_GAME_CONTROL_STATUS_PAUSED = "Pause";
export const DB_GAME_CONTROL_STATUS_TIME_MINUTES = "minutes";
export const DB_GAME_CONTROL_STATUS_TIME_SECONDS = "seconds";
export const DB_GAME_CONTROL_STATUS_TIME_START = "start_date";
export const DB_GAME_CONTROL_BLINDS_KEY = "blinds";
export const DB_GAME_CONTROL_BLIND_DURATION_KEY = "blind_duration_min";
export const DB_GAME_CONTROL_BLIND_LEVEL_KEY = "current_level";
export const DB_GAME_CONTROL_DEFAULT_BUYIN_KEY = "buyin";
export const DB_GAME_CONTROL_DEFAULT_CHIPS_KEY = "chips";
export const DB_GAME_CONTROL_PAYOUTS_KEY = "payouts";

export const DB_PATH_DOODLE = "/doodle/";
export const DB_PATH_DOODLE_SIGNUP = "/doodle/signups/";

export const DB_PATH_WINNERS = "/game_results/";

/******************************************************************************** */

const firebaseConfig = {
  apiKey: "AIzaSyDnrEaexENyWEPRFzDZn8ppM5rb8yONYLQ",
  authDomain: "ruemlifee-77064.firebaseapp.com",
  databaseURL:
    "https://ruemlifee-77064-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ruemlifee-77064",
  storageBucket: "ruemlifee-77064.appspot.com",
  messagingSenderId: "968969733368",
  appId: "1:968969733368:web:7dac1a70146f6c93dde70d",
  measurementId: "G-L0YRTKB30Y",
};
/******************************************************************************** */

export const usePersistedList = (
  path: string
): [Map<string, any>, boolean, Error | undefined] => {
  const [data, loading, error] = useList(ref(database, path));

  const map: Map<string, any> = new Map();
  if (!loading && data) {
    // console.log("data:", data);
    data.map((v: DataSnapshot) => {
      // console.log("key:", v.key, "value: ", v.val());
      // if (v.key === "minutes") debugger;
      return true;
    });
    data.map((v: DataSnapshot) => {
      if (v.key && typeof v.val() != "undefined" && !map.get(v.key)) {
        map.set(v.key, v.val());
      }
      return true;
    });
  }
  // console.log("map", map);

  return [map, loading, error];
};
/******************************************************************************** */

export const addRSVP = (name: string, participation: string) => {
  set(ref(database, "rsvp/" + name), { participation: participation });
  if (participation === "Ja") {
    addPlayer(name);
  }
};

/*********************************************************************************/
export const deleteRSVP = (name: string) => {
  set(ref(database, "rsvp/" + name), null);
};

/******************************************************************************** */

export const addPlayer = (name: string) => {
  set(ref(database, DB_PLAYER_PATH + name), { buyin: 0, alive: true });
};

/******************************************************************************** */

export const deletePlayer = (name: string) => {
  set(ref(database, DB_PLAYER_PATH + name), null);
};

/******************************************************************************** */
export const eliminatePlayer = async (name: string) => {
  const snapshot = await get(ref(database, DB_PLAYER_PATH + name));
  if (snapshot.exists()) {
    set(ref(database, DB_PLAYER_PATH + name), {
      buyin: snapshot.val().buyin,
      alive: snapshot.val().alive ? false : true,
    });
  }
};

/******************************************************************************** */
export const startGame = () => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_STATUS_TIME_START),
    new Date().getTime()
  );
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_STATUS_KEY),
    DB_GAME_CONTROL_STATUS_RUNNING
  );
};
/******************************************************************************** */
export const pauseGame = (data: DataTypes) => {
  console.log("pauseGame", data);
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_STATUS_KEY),
    DB_GAME_CONTROL_STATUS_PAUSED
  );
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_STATUS_TIME_START),
    new Date().getTime()
  );
  saveTime(data.minutes, data.seconds);
  saveLevel(String(data.level));
};

/******************************************************************************** */

export const saveTime = (minutes: number, seconds: number) => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_STATUS_TIME_MINUTES),
    minutes
  );
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_STATUS_TIME_SECONDS),
    seconds
  );
};

/******************************************************************************** */

export const saveLevel = (level: string) => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_BLIND_LEVEL_KEY),
    level
  );
};

/******************************************************************************** */

export const saveBlindDuration = (blindDuration: string) => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_BLIND_DURATION_KEY),
    blindDuration
  );
};

/******************************************************************************** */

export const saveBlindStructure = (blinds: string) => {
  set(ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_BLINDS_KEY), blinds);
};

/******************************************************************************** */

export const saveBuyin = (name: string, buyin: number, alive: boolean) => {
  set(ref(database, DB_PLAYER_PATH + name), { buyin: buyin, alive: alive });
};

/******************************************************************************** */

export const saveDefaultBuyin = (buyin: string) => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_DEFAULT_BUYIN_KEY),
    buyin
  );
};

/******************************************************************************** */
export const saveChipsAmount = (amount: string) => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_DEFAULT_CHIPS_KEY),
    amount
  );
};

/******************************************************************************** */
export const saveBuyinAmount = (amount: string) => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_DEFAULT_BUYIN_KEY),
    amount
  );
};

/******************************************************************************** */

export const savePayoutStructure = (payouts: string) => {
  set(
    ref(database, DB_GAME_CONTROL_PATH + DB_GAME_CONTROL_PAYOUTS_KEY),
    payouts
  );
};

/******************************************************************************** */

export const saveDoodleChoice = (name: string, participation: string) => {
  set(ref(database, DB_PATH_DOODLE_SIGNUP + name), participation);
};

/********************************************************************************/
export const saveWinners = (array: Array<string>) => {
  // console.log("saving winners", array);

  set(ref(database, DB_PATH_WINNERS), array);
};
/********************************************************************************/

const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
